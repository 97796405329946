import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { onRowUpdating, isAuditVisible, multiLineCell, multiLineCellNumeric } from "Utils/Utils";
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Format,
    Form, Popup, FormItem
} from 'devextreme-react/data-grid';
import { generalRegistryDataSource } from '../_GeneralRegistryDataSources';
import { RegistryReport } from 'components/RegistryReport/_RegistryReport';
import { tagBoxOptions } from 'components/Shared/TagBoxHonoraryOptions/_TagBoxHonoraryOptions';

export default class GeneralRegistryGrid extends Component {
    static displayName = GeneralRegistryGrid.name;

    constructor(props){
        super(props);

        this.grdRef = React.createRef();

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "GeneralRegistries")
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.nrCrtTextOptions = {
            placeholder: "Nr. crt. va fi generat automat",
            readOnly: true
        }

        this.tagBoxEditorOptions = {
            showSelectionControls: true,
            acceptCustomValue: true,
            dropDownOptions: {
                disabled: true
            },
            openOnFieldClick: false
        }

        this.radioEditorOptions = {
            dataSource: [
                "Acte autentice/ Imobiliare/ Acte vânzare",
                "Acte autentice/ Imobiliare/ Alte acte de transmitere a dreptului de proprietate (altele decât cele de la rubricile 6 şi 9), partaj",
                "Acte autentice/ Imobiliare/ Alipiri, dezlipiri, apartamentări",
                "Acte autentice/ Imobiliare/ Anulări modificări, încetări, stingeri",
                "Acte autentice/ Acte de ipotecă/ Mobiliare",
                "Acte autentice/ Acte de ipotecă/ Imobiliare",
                "Acte autentice/ Acte referitoare la regimul matrimonial",
                "Acte autentice/ Societăţi comerciale, agricole, societăţi civile, asociaţii, fundaţii Constituiri / Modificări şi hotărâri aferente",
                "Acte autentice/ Testament, codicil, acte de revocare ale acestora",
                "Acte autentice/ Alte acte",
                "Alte înregistrări/ Respingeri",
                "Alte înregistrări/ Rectificări, proiecte, alte operaţii (necuprinse în rubricile 18 - 23)",
                "Alte înregistrări/ Certificări",
                "Alte înregistrări/ Legalizări de semnătură, specimene de semnătură şi sigilii",
                "Alte înregistrări/ Încheieri şi proceduri prealabile",
                "Alte înregistrări/ Dată certă",
                "Alte înregistrări/ Legalizări copii",
                "Alte înregistrări/ Legalizări traduceri, legalizarea semnăturii traducătorului",
                "Alte înregistrări/ Duplicate"
            ]
        }

        this.formItems = [
            {
                itemType: "group",
                items: ["NrCrt", "Date", "PartiesList", "OperationType"]
            },
            {
                itemType: "group",
                items: ["DocumentType"]
            },
            {
                itemType: "group",
                caption: "Acte autentice",
                items: [
                    "IdentificationNumber",
                ]
            },
            {
                itemType: "group",
                items:["Price", "Evaluation"]
            },
            {
                itemType: "group",
                caption: "Taxe",
                items: ["Tax", "AncpiTarrif", "HonoraryList"]
            },
            {
                itemType: "group",
                caption: "Scutiri",
                items: ["TaxExemptions", "TaxReductions"]
            },
            {
                itemType: "group",
                items: ["PublicRegister", "Observations"]
            }
        ]
    }

    render() {
        return (
            <div>
                <div className="container-datagrid">
                    <DataGrid
                        dataSource={generalRegistryDataSource()}
                        ref={this.grdRef}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        width="auto"
                        height="84.7vh"
                        remoteOperations={true}
                        onRowUpdating={onRowUpdating}
                        onToolbarPreparing={(e) => {
                            e.toolbarOptions.items.unshift({
                                location: "after",
                                hint: "Export PDF",
                                widget: "dxButton",
                                options: {
                                    icon: "pdffile",
                                    onClick: (e) => {
                                        const component = ReactDOM.render(<RegistryReport Registry="general-registries" HasOpis={true} />, document.getElementById("registryPopupContainer"));
                                        component.forceUpdate();
                                    }
                                }
                            })
                        }}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />

                        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true} >
                            <Form colCount={1} title="Registru general" items={this.formItems} labelLocation="top">
                            </Form>
                            <Popup title="Registru general" showTitle={true} minWidth="20em" maxWidth="70em" height="80vh" />
                        </Editing>

                        <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                            <Button name="edit" cssClass="underlineBtn"/>
                            <Button name="delete" cssClass="underlineBtn" />
                            <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        </Column>

                        <Column dataField="NrCrt" caption="Nr. crt." cssClass="vertical-center" alignment="center" minWidth="10em" dataType="string" editorType="dxTextBox"
                            editorOptions={this.nrCrtTextOptions}
                        >
                        </Column>

                        <Column caption="Data" cssClass="vertical-center" alignment="center">
                            <Column caption="Anul" cssClass="vertical-center" alignment="center">
                                <Column caption="Ziua/Luna" dataField="Date" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                                    editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                                >
                                    <RequiredRule message="Câmp obligatoriu!" />
                                </Column>
                            </Column>
                        </Column>

                        <Column dataField="Parties" caption="Numele, prenumele și/sau denumirea parților" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.Parties); }}
                        >
                            <FormItem visible={false} />
                        </Column>

                        <Column dataField="PartiesList" caption="Numele, prenumele și/sau denumirea parților"
                            visible={false} editorType="dxTagBox" editorOptions={this.tagBoxEditorOptions}
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                            <FormItem helpText="Tastați numele și apăsați Enter pentru a înregistra valoarea." />
                        </Column>

                        <Column dataField="OperationType" caption="Felul actului si/sau operatiunilor" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.OperationType); }} editorType="dxTextArea"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column dataField="DocumentType" caption="Tip act" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.DocumentType); }} editorType="dxRadioGroup" editorOptions={this.radioEditorOptions}
                            allowSorting={false} allowFiltering={false}
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column caption="Acte autentice" cssClass="vertical-center" alignment="center" minWidth="10em">
                            <Column caption="Nr. de autentificare" cssClass="vertical-center" alignment="center" minWidth="10em" dataField="IdentificationNumber">
                            </Column>
                        </Column>
                            

                        <Column dataField="Price" caption="Preţ" cssClass="vertical-center" alignment="center" minWidth="10em"
                            editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                            cellRender={function(e) {return multiLineCellNumeric(e.data.Price); }}
                        > 
                            <Format type="fixedPoint" precision={2} />
                        </Column>

                        <Column dataField="Evaluation" caption="Evaluare" cssClass="vertical-center" alignment="center" minWidth="10em"
                            editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                            cellRender={function(e) {return multiLineCellNumeric(e.data.Evaluation); }}
                        > 
                            <Format type="fixedPoint" precision={2} />
                        </Column>

                        <Column caption="Taxe" cssClass="vertical-center" alignment="center" minWidth="10em">
                            <Column dataField="Tax" caption="Impozit" cssClass="vertical-center" alignment="center" minWidth="10em"
                                editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                                cellRender={function(e) {return multiLineCellNumeric(e.data.Tax); }}
                            > 
                                <Format type="fixedPoint" precision={2} />
                            </Column>

                            <Column dataField="AncpiTarrif" caption="Tarif ANCPI" cssClass="vertical-center" alignment="center" minWidth="10em"
                                editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                                cellRender={function(e) {return multiLineCellNumeric(e.data.AncpiTarrif); }}
                            > 
                                <Format type="fixedPoint" precision={2} />
                            </Column>

                            <Column dataField="Honorary" caption="Onorariul încasat" cssClass="vertical-center" alignment="center" minWidth="10em"
                                cellRender={function(e) {return multiLineCellNumeric(e.data.Honorary); }}
                            > 
                                <FormItem visible={false} />
                            </Column>

                            <Column dataField="HonoraryList" caption="Onorariul încasat"
                                visible={false} editorType="dxTagBox" editorOptions={tagBoxOptions}
                            >
                                <RequiredRule message="Câmp obligatoriu!" />
                                <FormItem helpText="Tastați onorariul și apăsați Enter pentru a înregistra valoarea." />
                            </Column>
                        </Column>

                        <Column caption="Scutiri si reduceri" cssClass="vertical-center" alignment="center" minWidth="10em">
                            <Column dataField="TaxExemptions" caption="Scutiri" cssClass="vertical-center" alignment="center" minWidth="10em"
                                editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                                cellRender={function(e) {return multiLineCellNumeric(e.data.TaxExemptions); }}
                            > 
                                <Format type="fixedPoint" precision={2} />
                            </Column>

                            <Column dataField="TaxReductions" caption="Reduceri" cssClass="vertical-center" alignment="center" minWidth="10em"
                                editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                                cellRender={function(e) {return multiLineCellNumeric(e.data.TaxReductions); }}
                            > 
                                <Format type="fixedPoint" precision={2} />
                            </Column>
                        </Column>

                        <Column caption="Notar public" 
                            cssClass="vertical-center" alignment="center" minWidth="10em" dataField="PublicRegister"
                            cellRender={function(e) {return multiLineCell(e.data.PublicRegister); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column caption="Observaţii" 
                            cssClass="vertical-center" alignment="center" minWidth="10em" dataField="Observations"
                            cellRender={function(e) {return multiLineCell(e.data.Observations); }} editorType="dxTextArea"
                        >
                        </Column>
                    </DataGrid>
                </div>
            </div>
        )
    }
}
