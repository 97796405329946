export const addDays = (dateValue, days) => {
    var date = new Date(dateValue)
    date.setDate(date.getDate() + days);
    return date;
}

export const lastDayOfMonth = (dateValue) => {
    var month = new Date(dateValue).getMonth();

    return new Date(new Date(dateValue).getFullYear(), month + 1, 0);
}

export const firstDayOfMonth = (dateValue) => {
    var month = new Date(dateValue).getMonth();

    return new Date(new Date(dateValue).getFullYear(), month, 1)
}

export const lastDayOfNextMonth = (dateValue) => {
    var month = new Date(dateValue).getMonth();

    return new Date(new Date(dateValue).getFullYear(), month + 2, 0);
}