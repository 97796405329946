import notify from "devextreme/ui/notify";

const decimalChecker = (text) => {
    var regex = /^[1-9][0-9]*(\.[0-9][0-9]?)?/g
    var found = text.match(regex)
    if(found !== null && found[0] === text){
        return Number(found[0])
    }

    var commaRegex = /^[1-9][0-9]*(,[0-9][0-9]?)?/g
    var commaFound = text.match(commaRegex)
    if(commaFound !== null && commaFound[0] === text){
        return Number(commaFound[0].replace(",", "."))
    }
    return null;
}

const intChecker = (text) => {
    var regex = /^[1-9][0-9]*/g
    var found = text.match(regex)
    if(found !== null && found[0] === text){
        return Number(found[0].replace(",", ""))
    }
    return null;
}

const decimalCommaChecker = (text) => {
    var regex = /^([1-9]{1,3})(,[0-9]{3})*(\.[0-9][0-9]?)?/g
    var found = text.match(regex);
    if(found !== null && found[0] === text){
        return Number(found[0].replace(",", ""))
    }
    return null;
}

const decimalPointChecker = (text) => {
    var regex = /^([1-9]{1,3})(\.[0-9]{3})*(,[0-9][0-9]?)?/g
    var found = text.match(regex);
    if(found !== null && found[0] === text){
        return Number(found[0].replace(".", "").replace(",", "."))
    }
    return null;
}

export const tagBoxOptions = {
    showSelectionControls: true,
    acceptCustomValue: true,
    dropDownOptions: {
        disabled: true
    },
    openOnFieldClick: false,
    onCustomItemCreating: (e) => {
        //TODO: find locale
        // console.log(intChecker(e.text).toLocaleString("ro-RO", {minimumFractionDigits: 2, maximumFractionDigits: 2}))
        if(intChecker(e.text) !== null) {e.customItem = (e.component.option("value").length + 1) + ": " + intChecker(e.text).toLocaleString("ro-RO", {minimumFractionDigits: 2, maximumFractionDigits: 2}); return; }
        if(decimalChecker(e.text) !== null) {e.customItem = (e.component.option("value").length + 1) + ": " + decimalChecker(e.text).toLocaleString("ro-RO", {minimumFractionDigits: 2, maximumFractionDigits: 2}); return;}
        if(decimalCommaChecker(e.text) !== null) {e.customItem = (e.component.option("value").length + 1) + ": " + decimalCommaChecker(e.text).toLocaleString("ro-RO", {minimumFractionDigits: 2, maximumFractionDigits: 2}); return;}
        if(decimalPointChecker(e.text) !== null) {e.customItem = (e.component.option("value").length + 1) + ": " + decimalPointChecker(e.text).toLocaleString("ro-RO", {minimumFractionDigits: 2, maximumFractionDigits: 2}); return;}
        e.customItem = null;
        notify("Valoarea introdusă nu este numerică", "error", 5000)
        
    } 
}

export const updateRequest = (honoraryList) => {
    var floatHonoraryList = [];
    for(var i = 0; i < honoraryList.length; i++){
        floatHonoraryList[i] = decimalPointChecker(honoraryList[i].split(": ")[1]);
    }
    return floatHonoraryList;
}