import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { onRowUpdating, isAuditVisible, multiLineCell, multiLineCellNumeric } from "Utils/Utils";
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Format,
    Form, Popup, FormItem,
} from 'devextreme-react/data-grid';
import { successionTermsRegistryDataSource } from '../_SuccessionTermsDataSource';
import { RegistryReport } from 'components/RegistryReport/_RegistryReport';
import { tagBoxOptions } from 'components/Shared/TagBoxHonoraryOptions/_TagBoxHonoraryOptions';

export default class SuccessionTermsRegistryGrid extends Component {
    static displayName = SuccessionTermsRegistryGrid.name;

    constructor(props){
        super(props);

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "SuccessionTerms")
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.nrCrtTextOptions = {
            placeholder: "Numărul de înregistrare va fi generat automat",
            readOnly: true
        }
    }

    render() {
        return(
            <div className="container-datagrid">
                <div>
                    <DataGrid
                        dataSource={successionTermsRegistryDataSource()}
                        ref={this.grdRef}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        width="auto"
                        height="84.7vh"
                        remoteOperations={true}
                        onRowUpdating={onRowUpdating}
                        onToolbarPreparing={(e) => {
                            e.toolbarOptions.items.unshift({
                                location: "after",
                                hint: "Export PDF",
                                widget: "dxButton",
                                options: {
                                    icon: "pdffile",
                                    onClick: (e) => {
                                        const component = ReactDOM.render(<RegistryReport Registry="succesion-terms-registries" HasOpis={false} />, document.getElementById("registryPopupContainer"));
                                        component.forceUpdate();
                                    }
                                }
                            })
                        }}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />

                        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true} >
                            <Form colCount={1} title="Registru de termene succesorale" items={this.formItems} labelLocation="top">
                            </Form>
                            <Popup title="Registru de termene succesorale" showTitle={true} minWidth="20em" maxWidth="70em" height="80vh" />
                        </Editing>

                        <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                            <Button name="edit" cssClass="underlineBtn"/>
                            <Button name="delete" cssClass="underlineBtn" />
                            <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        </Column>

                        <Column caption="Data" dataField="Date" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                        >
                            <RequiredRule message="Camp obligatoriu!" />
                        </Column>

                        <Column dataField="RegistrationNumber" caption="Numărul de ordine" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="string" editorType="dxTextBox"
                            editorOptions={this.nrCrtTextOptions}
                        >
                        </Column>

                        <Column dataField="DeceasedName" caption="Numele şi prenumele defunctului" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.DeceasedName); }} editorType="dxTextArea"
                        >
                        </Column>
                        <Column dataField="SuccessionFileNumber" caption="Numărul dosarului succesoral" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.SuccessionFileNumber); }} editorType="dxTextArea"
                        >
                        </Column>
                        <Column dataField="Result" caption="Soluţia (amânare, suspendare sau finalizare)" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.Result); }} editorType="dxTextArea"
                        >
                        </Column>
                        <Column dataField="CertificateNumber" caption="Numărul certificatului (de moştenitor, de legatar, de calitate, succesoral de lichidare, de vacanţă, de executor testamentar)" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.CertificateNumber); }} editorType="dxTextArea"
                        >
                        </Column>
                        <Column dataField="Honorary" caption="Onorariul încasat" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCellNumeric(e.data.Honorary); }}
                        > 
                            <FormItem visible={false} />
                        </Column>

                        <Column dataField="HonoraryList" caption="Onorariul încasat"
                            visible={false} editorType="dxTagBox" editorOptions={tagBoxOptions}
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                            <FormItem helpText="Tastați onorariul și apăsați Enter pentru a înregistra valoarea." />
                        </Column>
                        <Column dataField="Tax" caption="Impozitul" cssClass="vertical-center" alignment="center" minWidth="10em"
                            editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                            cellRender={function(e) {return multiLineCellNumeric(e.data.Tax); }}
                        > 
                            <Format type="fixedPoint" precision={2} />
                        </Column>
                        <Column dataField="AncpiTarrif" caption="Tariful ANCPI" cssClass="vertical-center" alignment="center" minWidth="10em"
                            editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                            cellRender={function(e) {return multiLineCellNumeric(e.data.AncpiTarrif); }}
                        > 
                            <Format type="fixedPoint" precision={2} />
                        </Column>
                    </DataGrid>
                </div>
            </div>

        )
    }
}