import { updateRequest } from 'components/Shared/TagBoxHonoraryOptions/_TagBoxHonoraryOptions';
import CustomStore from 'devextreme/data/custom_store';
import { isNotEmpty, handleErrors } from "Utils/Utils";

export const successionRegistryDataSource = () => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/successionregistries${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/successionregistries/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        },
        insert: (values) => {
            values["HonoraryList"] = updateRequest(values["HonoraryList"]);
            return fetch("/api/successionregistries", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        update: (key, values) => {
            values["HonoraryList"] = updateRequest(values["HonoraryList"]);
            return fetch(`/api/successionregistries/${key}`, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch(`/api/successionregistries/${key}`, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}