import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { onRowUpdating, isAuditVisible, multiLineCell, multiLineCellNumeric } from "Utils/Utils";
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Format,
    Form, Popup, FormItem,
} from 'devextreme-react/data-grid';
import { successionRegistryDataSource } from '../_SuccessionRegistryDataSources';
import { RegistryReport } from 'components/RegistryReport/_RegistryReport';
import { tagBoxOptions } from 'components/Shared/TagBoxHonoraryOptions/_TagBoxHonoraryOptions';


export default class SuccessionRegistryGrid extends Component {
    static displayName = SuccessionRegistryGrid.name;

    constructor(props){
        super(props);

        this.grdRef = React.createRef();

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "SuccessionRegistries")
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.nrCrtTextOptions = {
            placeholder: "Numărul de înregistrare va fi generat automat",
            readOnly: true
        }

        this.tagBoxEditorOptions = {
            showSelectionControls: true,
            acceptCustomValue: true,
            dropDownOptions: {
                disabled: true
            },
            openOnFieldClick: false
        }
    }

    render() {
        return(
            <div>
                <div className="container-datagrid">
                    <DataGrid
                        dataSource={successionRegistryDataSource()}
                        ref={this.grdRef}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        width="auto"
                        height="84.7vh"
                        remoteOperations={true}
                        onRowUpdating={onRowUpdating}
                        onToolbarPreparing={(e) => {
                            e.toolbarOptions.items.unshift({
                                location: "after",
                                hint: "Export PDF",
                                widget: "dxButton",
                                options: {
                                    icon: "pdffile",
                                    onClick: (e) => {
                                        const component = ReactDOM.render(<RegistryReport Registry="succession-registries" HasOpis={true} />, document.getElementById("registryPopupContainer"));
                                        component.forceUpdate();
                                    }
                                }
                            })
                        }}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />

                        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true} >
                            <Form colCount={1} title="Registrul de succesiuni" items={this.formItems} labelLocation="top">
                            </Form>
                            <Popup title="Registrul de succesiuni" showTitle={true} minWidth="20em" maxWidth="70em" height="80vh" />
                        </Editing>

                        <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                            <Button name="edit" cssClass="underlineBtn"/>
                            <Button name="delete" cssClass="underlineBtn" />
                            <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        </Column>

                        <Column dataField="RegistrationNumber" caption="Nr. înregistrare" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="string" editorType="dxTextBox"
                            editorOptions={this.nrCrtTextOptions}
                        >
                        </Column>

                        <Column caption="Data înregistrării" dataField="RegistrationDate" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column dataField="DeceasedName" caption="Numele şi prenumele defunctului" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.DeceasedName); }} editorType="dxTextArea"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column dataField="DeceasedDateOfBirth" caption="Data naşterii a defunctului" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                        >
                        </Column>

                        <Column dataField="DeceasedPersonalNumericCode" caption="CNP-ul defunctului" cssClass="vertical-center" alignment="center" minWidth="10em"
                            editorType="dxTextBox"
                        >
                        </Column>

                        <Column dataField="DeceasedAddress" caption="Ultimul domiciliu al defunctului" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.DeceasedAddress); }} editorType="dxTextArea"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column caption="Data decesului" dataField="DeceaseDate" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                        >
                        </Column>

                        <Column dataField="SuccessionProceduresNumber" caption="Numărul de înregistrare din registrul de evidenţă a procedurilor succesorale"
                            cellRender={function(e) {return multiLineCell(e.data.SuccessionProceduresNumber); }} editorType="dxTextArea" cssClass="vertical-center" alignment="center" minWidth="10em"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column dataField="SuccessionCertificateNumbers" caption="Numerele de înregistrare ale certificatelor eliberate de CNARNN"
                            cellRender={function(e) {return multiLineCell(e.data.SuccessionCertificateNumbers); }} editorType="dxTextArea" cssClass="vertical-center" alignment="center" minWidth="10em"
                        >
                            <FormItem visible="false" />
                        </Column>

                        <Column dataField="SuccessionCertificateNumbersList" caption="Numerele de înregistrare ale certificatelor eliberate de CNARNN"
                            visible={false} editorType="dxTagBox" editorOptions={this.tagBoxEditorOptions}
                        >
                            <FormItem helpText="Tastați numărul certificatului și apăsați Enter pentru a înregistra valoarea." />
                        </Column>

                        <Column dataField="Term" caption="Termen" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                        >
                        </Column>

                        <Column dataField="BirthCertificateNumber" caption="Numărul şi data certificatului (moştenitor, legatar, calitate, succesoral de lichidare, executor testamentar, vacanţă succesorală)" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.BirthCertificateNumber); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="SuccessionProceduresSuspension" caption="Suspendarea procedurii succesorale" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.SuccessionProceduresSuspension); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="OtherSolutions" caption="Alte soluţii (conexare, disjungere, clasare fără obiect etc.)" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.OtherSolutions); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="Tax" caption="Impozit" cssClass="vertical-center" alignment="center" minWidth="10em"
                            editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                            cellRender={function(e) {return multiLineCellNumeric(e.data.Tax); }}
                        > 
                            <Format type="fixedPoint" precision={2} />
                        </Column>

                        <Column dataField="Honorary" caption="Onorariul încasat" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCellNumeric(e.data.Honorary); }}
                        > 
                            <FormItem visible={false} />
                        </Column>

                        <Column dataField="HonoraryList" caption="Onorariul încasat"
                            visible={false} editorType="dxTagBox" editorOptions={tagBoxOptions}
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                            <FormItem helpText="Tastați onorariul și apăsați Enter pentru a înregistra valoarea." />
                        </Column>

                        <Column dataField="AncpiTarif" caption="Tarif ANCPI" cssClass="vertical-center" alignment="center" minWidth="10em"
                            editorType="dxNumberBox" editorOptions={{mode: "number", min: 0.00, format: {type: "fixedPoint", precision: 2}, defaultValue: 0.00}}
                        > 
                            <Format type="fixedPoint" precision={2} />
                        </Column>

                        <Column dataField="PublicNotary" caption="Notarul public" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.PublicNotary); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="Observations" caption="Observaţii (suplimentări, reînregistrări etc.)" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.Observations); }} editorType="dxTextArea"
                        >
                        </Column>
                    </DataGrid>
                </div>
            </div>
        )
    }
}