import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { onRowUpdating, isAuditVisible, multiLineCell } from "Utils/Utils";
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Form, Popup,
} from 'devextreme-react/data-grid';
import { corespondanceRegistryDataSource } from '../_CorespondanceRegistryDataSources';
import { RegistryReport } from 'components/RegistryReport/_RegistryReport';

export default class CorespondanceRegistryGrid extends Component{
    static displayName = CorespondanceRegistryGrid.name;

    constructor(props){
        super(props);

        this.grdRef = React.createRef();

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "CorespondanceRegistries")
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.nrCrtTextOptions = {
            placeholder: "Numărul de înregistrare va fi generat automat",
            readOnly: true
        }
    }

    render() {
        return (
            <div>
                <div className="container-datagrid">
                    <DataGrid
                        dataSource={corespondanceRegistryDataSource()}
                        ref={this.grdRef}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        width="auto"
                        height="84.7vh"
                        remoteOperations={true}
                        onRowUpdating={onRowUpdating}
                        onToolbarPreparing={(e) => {
                            e.toolbarOptions.items.unshift({
                                location: "after",
                                hint: "Export PDF",
                                widget: "dxButton",
                                options: {
                                    icon: "pdffile",
                                    onClick: (e) => {
                                        const component = ReactDOM.render(<RegistryReport Registry="corespondance-registries" HasOpis={false} />, document.getElementById("registryPopupContainer"));
                                        component.forceUpdate();
                                    }
                                }
                            })
                        }}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />

                        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true} >
                            <Form colCount={1} title="Registrul de corespondenţă"  labelLocation="top"/>
                            <Popup title="Registrul de corespondenţă" showTitle={true} minWidth="20em" maxWidth="70em" height="80vh" />
                        </Editing>

                        <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                            <Button name="edit" cssClass="underlineBtn"/>
                            <Button name="delete" cssClass="underlineBtn" />
                            <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        </Column>

                        <Column dataField="RegistrationNumber" caption="Număr de înregistrare" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="string" editorType="dxTextBox"
                            editorOptions={this.nrCrtTextOptions}
                        >
                        </Column>

                        <Column caption="Data înregistrării/expedierii" dataField="RegistrationDate" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column dataField="Destination" caption="Emitent/Destinatar" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.Destination); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="CorespondanceReason" caption="Obiectul corespondenţei şi modul de soluţionare" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.CorespondanceReason); }} editorType="dxTextArea"
                        >
                        </Column>
                        <Column dataField="Observations" caption="Observaţii" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.Observations); }} editorType="dxTextArea"
                        >
                        </Column>
                    </DataGrid>
                </div>
            </div>
        )
    }
}