import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Login } from './components/Authentication/_Login';
import { ConfirmAccount } from 'components/Authentication/_ConfirmAccount';
import { Register } from "./components/Authentication/_Register.js";
import { SetPassword } from 'components/SetPassword/_SetPassword';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { locale } from "devextreme/localization";
import './reset.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './custom.css';
import GeneralRegistry from 'components/GeneralRegistry/_GeneralRegistry';
import { CorespondanceRegistry } from 'components/CorespondanceRegistry/_CorespondanceRegistry';
import { NotaryConsultationsRegistry } from 'components/NotaryConsultationsRegistry/_NotaryConsultationRegistry';
import { ProtestsRegistry } from 'components/ProtestsRegistry/_ProtestsRegistry';
import DepositRegistry from 'components/DepositRegistry/_DepositRegistry';
import { SuccessionTermsRegistry } from 'components/SuccessionTermsRegistry/_SuccessionTermsRegistry';
import { SuccessionRegistry } from 'components/SuccessionRegistry/_SuccessionRegistry';
import { DivorceRegistry } from 'components/DivorceRegistry/_DivorceRegistry';


export default class App extends Component {
    constructor(props) {
        super(props);
        locale(navigator.language)
    }
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                <Route path='/Autentificare' component={Login} />
                <Route path="/resetPassword/:id/:token" component={SetPassword} />
                <Route path='/confirmaccount/:userId' component={ConfirmAccount} />
                <Route path="/register" component={Register} />
                <Route path="/registru-general" component={GeneralRegistry} />
                <Route path="/registru-corespondenta" component={CorespondanceRegistry} />
                <Route path="/registru-consultatii" component={NotaryConsultationsRegistry} />
                <Route path="/registru-proteste" component={ProtestsRegistry} />
                <Route path="/registru-depozitare" component={DepositRegistry} />
                <Route path="/registru-termeni-succesorale" component={SuccessionTermsRegistry} />
                <Route path="/registru-succesiuni" component={SuccessionRegistry} />
                <Route path="/registru-divorturi" component={DivorceRegistry} />
            </Layout> 


            
          
        );
    }
}



