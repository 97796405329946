import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, GroupItem } from "devextreme-react/form";
import CorespondanceRegistryGrid from './CorespondanceRegstryGrid/_CorespondanceRegstryGrid';

export class CorespondanceRegistry extends Component{
    static displayName = CorespondanceRegistry.name;

    render() {
        return(
            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Registrul de corespondenţă">
                        <Item>
                            <CorespondanceRegistryGrid />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
                <div id="registryPopupContainer" />
            </Fragment>
        )
    }
}