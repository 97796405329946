import { updateRequest } from 'components/Shared/TagBoxHonoraryOptions/_TagBoxHonoraryOptions';
import CustomStore from 'devextreme/data/custom_store';
import { isNotEmpty, handleErrors } from "Utils/Utils";

const objectProps = {
    "Acte autentice/ Imobiliare/ Acte vânzare": "SellingDocument",
    "Acte autentice/ Imobiliare/ Alte acte de transmitere a dreptului de proprietate (altele decât cele de la rubricile 6 şi 9), partaj": "OtherSellingDocuments",
    "Acte autentice/ Imobiliare/ Alipiri, dezlipiri, apartamentări": "Additions",
    "Acte autentice/ Imobiliare/ Anulări modificări, încetări, stingeri": "RemovedAdditions",
    "Acte autentice/ Acte de ipotecă/ Mobiliare": "Securities",
    "Acte autentice/ Acte de ipotecă/ Imobiliare": "Immobility",
    "Acte autentice/ Acte referitoare la regimul matrimonial": "MatrimonialRegime",
    "Acte autentice/ Societăţi comerciale, agricole, societăţi civile, asociaţii, fundaţii Constituiri / Modificări şi hotărâri aferente": "Societies",
    "Acte autentice/ Testament, codicil, acte de revocare ale acestora": "Testament",
    "Acte autentice/ Alte acte": "OtherDocuments",
    "Alte înregistrări/ Respingeri": "Rejections",
    "Alte înregistrări/ Rectificări, proiecte, alte operaţii (necuprinse în rubricile 18 - 23)": "Reworkings",
    "Alte înregistrări/ Certificări": "Certifications",
    "Alte înregistrări/ Legalizări de semnătură, specimene de semnătură şi sigilii": "SignatureLegalization",
    "Alte înregistrări/ Încheieri şi proceduri prealabile": "PreliminaryProcedures",
    "Alte înregistrări/ Legalizări copii": "LegalizedCopies",
    "Alte înregistrări/ Legalizări traduceri, legalizarea semnăturii traducătorului": "LegalizedTranslations",
    "Alte înregistrări/ Duplicate": "Duplicates",
    "Alte înregistrări/ Dată certă": "CertainDate"
}

export const generalRegistryDataSource = () => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/generalregistries${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/generalregistries/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        },
        insert: (values) => {
            values[objectProps[values.DocumentType]] = 1;
            values["HonoraryList"] = updateRequest(values["HonoraryList"]);
            return fetch("/api/generalregistries", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        update: (key, values) => {
            values[objectProps[values.DocumentType]] = 1;
            values["HonoraryList"] = updateRequest(values["HonoraryList"]);
            return fetch(`/api/generalregistries/${key}`, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch(`/api/generalregistries/${key}`, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}