import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import Form, { Item, RequiredRule, EmailRule, ButtonItem } from "devextreme-react/form";
import notify from 'devextreme/ui/notify';

export class ForgotPassword extends Component {
    static displayName = ForgotPassword.name;

    constructor(props) {
        super(props);

        this.passwordRef = React.createRef();

        this.state = {
            visible: false
        }

        this.hidePopup = (e) => {
            this.setState({
                visible: false
            })
        }

        this.forgotPasswordButtonOnClick = (e) => {
            this.setState({
                visible: true
            })
        }

        this.sendPasswordReset = (e) => {
            var email = this.passwordRef.current.instance.option("formData");
            fetch("/account/forgot-password", {
                method: "POST",
                body: JSON.stringify(email),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 200) {
                    this.hidePopup();
                    notify("An email was sent to the specified address. Please follow the instructions within to change your password.", "success", 6000)
                }
                else {
                    notify("This email address doesn't have an associated account.", "error", 3000)
                }
            })
        }

        this.forgotPasswordSubmitOptions = {
            onClick: (e) => { this.sendPasswordReset(e) },
            text: "Reset password"
        }
    }

    render() {
        return (
            <div className="popup-wrapper">
                <Button text="I forgot my password" onClick={this.forgotPasswordButtonOnClick} className="login-buttons" />
                <Popup
                    visible={this.state.visible}
                    onHiding={this.hidePopup}
                    showTitle={true}
                    title="I forgot my password"
                    height="auto"
                    minWidth="50vw"
                    maxWidth="80vw"
                >
                    <Form
                        ref={this.passwordRef}
                        showColonAfterLabel={true}
                        labelLocation="left"
                        colCount={1}
                    >
                        <Item
                            dataField="Email"
                            editorType="dxTextBox"
                            label={{ text: "Email Address" }}
                            width="100em"
                        >
                            <RequiredRule message="Email address must not be empty!" />
                            <EmailRule message="Email address does not have a correct format!" />
                        </Item>
                        <ButtonItem horizontalAlignment="center" buttonOptions={this.forgotPasswordSubmitOptions} />
                    </Form>
                </Popup>
            </div>
        )
    }
}