import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, GroupItem } from "devextreme-react/form";
import NotaryConsultationsRegistryGrid from './NotaryConsultationRegistryGrid/_NotaryConsultationRegistryGrid';

export class NotaryConsultationsRegistry extends Component {
    static displayName = NotaryConsultationsRegistry.name;

    render() {
        return(
            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Registrul de consultaţii notariale">
                        <Item>
                            <NotaryConsultationsRegistryGrid />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
                <div id="registryPopupContainer" />
            </Fragment>
        )
    }
}