import React from "react";
import { Popup } from 'devextreme-react/popup';
import { Form } from 'devextreme-react';
import { ButtonItem,  Item, Label, RequiredRule } from "devextreme-react/form";
import { firstDayOfMonth, lastDayOfMonth } from "Utils/DateManipulationFunctions";
import notify from "devextreme/ui/notify";
import { GetCustomDate_dd_MM_yyy } from "Utils/Utils";

export class RegistryReport extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            Registry: props.Registry,
            PopupVisible: false,
            HasOpis: false
        };

        this.formRef = React.createRef();
        this.popupRef = React.createRef();
        this.startDateRef = React.createRef();
        this.endDateRef = React.createRef();

        this.registries = {
            "general-registries": "RegistruGeneral",
            "succession-registries": "RegistruSuccesiuni",
            "divorce-registries": "RegistruDivorturi",
            "succesion-terms-registries": "RegistruTermeneSuccesoriale",
            "protests-registries": "RegistruProteste",
            "notary-consultations-registries": "RegistruConsultatiiNotariale",
            "corespondance-registries": "RegistruCorespondente",
            "deposit-registries": "RegistruDepozite"
        }

        this.showPopUp = () => {
            this.state.PopupVisible = true;
        }

        this.hidePopUp = () => {
            this.state.PopupVisible = false;
        }

        this.startDateBoxOptions = {
            value: firstDayOfMonth(new Date().valueOf()),
            onInitialized: (e) => {
                e.component.option("value", firstDayOfMonth(new Date().valueOf()))
            },
            displayFormat: "dd.MM.yyyy"
        }

        this.endDateBoxOptions = {
            value: lastDayOfMonth(new Date().valueOf()),
            onInitialized: (e) => {
                e.component.option("value", lastDayOfMonth(new Date().valueOf()))
            },
            displayFormat: "dd.MM.yyyy"
        }

        this.colCountOptions = (HasOpis) => {
            if(HasOpis) return 1;
            else return 2;
        }

        this.buttonOptions = {
            width: "15em",
            text: "Descărcare raport",
            onClick: async (e) => {
                var formData = this.formRef.current.instance.option("formData")
                var response = await fetch(`/api/reporting/${this.state.Registry}?startDate=${formData.startDate.valueOf()/1000}&endDate=${formData.endDate.valueOf()/1000}`)
                if(response.status === 200){
                    var blob = await response.blob()
                    const url = window.URL.createObjectURL(
                        new Blob([blob])
                    );

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${this.registries[this.state.Registry]}_${GetCustomDate_dd_MM_yyy(formData.startDate)}_${GetCustomDate_dd_MM_yyy(formData.endDate)}.pdf`
                    )
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    this.popupRef.current.instance.hide();
                }
                else{
                    var body = await response.json();
                    notify(body.detail, "info", 3000)
                }
            }
        }

        this.opisButtonOptions = {
            width: "15em",
            text: "Descărcare opis",
            onClick: async (e) => {
                if(!this.state.HasOpis){
                    notify("Nu există opis pentru acest registru!", "info", 3000)
                    return;
                }
                var formData = this.formRef.current.instance.option("formData")
                var response = await fetch(`/api/reporting/${this.state.Registry}-opis?startDate=${formData.startDate.valueOf()/1000}&endDate=${formData.endDate.valueOf()/1000}`)
                if(response.status === 200){
                    var blob = await response.blob()
                    const url = window.URL.createObjectURL(
                        new Blob([blob])
                    );

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${this.registries[this.state.Registry]}Opis_${GetCustomDate_dd_MM_yyy(formData.startDate)}_${GetCustomDate_dd_MM_yyy(formData.endDate)}.pdf`
                    )
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    this.popupRef.current.instance.hide();
                }
                else{
                    var body = await response.json();
                    notify(body.detail, "info", 3000)
                }
            }
            
        }
    }

    render() {
        return (
            <div>
                <Popup
                    onHiding={this.hidePopUp}
                    dragtEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    ref={this.popupRef}
                    title="Export PDF"
                    width="25vw"
                    height="auto"
                >
                    <Form
                        colCount={2}
                        ref={this.formRef}
                        showColonAfterLabel={true}
                        labelLocation="top"
                    >
                        <Item 
                            colSpan={2}
                            editorType="dxDateBox"
                            dataField="startDate"
                            editorOptions={this.startDateBoxOptions}
                        >
                            <Label text="Data de start" />
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Item>
                        <Item 
                            colSpan={2}
                            editorType="dxDateBox"
                            dataField="endDate"
                            editorOptions={this.endDateBoxOptions}
                        >
                            <Label text="Data final" />
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Item>
                        
                        <ButtonItem colSpan={this.colCountOptions(this.state.HasOpis)} verticalAlignment="bottom" horizontalAlignment="right" buttonOptions={this.buttonOptions} />
                        <ButtonItem colSpan={1} verticalAlignment="bottom" horizontalAlignment="left" buttonOptions={this.opisButtonOptions}  visible={this.state.HasOpis}/>
                    </Form>
                </Popup>
            </div>
        )
    }

    componentDidUpdate(prevProps){
        const { Registry, HasOpis } = prevProps;
        if(this.props.Registry !== Registry || this.props.HasOpis !== HasOpis){
            this.setState({
                Registry: Registry,
                HasOpis: HasOpis
            });
        }
        this.popupRef.current.instance.show();
    }

    componentDidMount(props) {
        const { Registry, HasOpis } = this.props;
        this.setState({
            Registry: Registry,
            HasOpis: HasOpis
        });
        this.popupRef.current.instance.show();
    }
}