import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Accordion, { Item } from 'devextreme-react/accordion';
import './Accordation.css';
import './Drawer.css';

class NavigationList extends Component {
    render() {
        return (
            <div className="list" >
                <Accordion
                    collapsible={true}
                    multiple={false}
                    animationDuration={300}
                >
                    <Item title="Registre">
                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/registru-general">Registrul general notarial</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/registru-succesiuni">Registrul de succesiuni</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/registru-termeni-succesorale">Registrul de termene succesorale</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/registru-depozitare">Registrul de depozite</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/registru-proteste">Registrul de proteste</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/registru-consultatii">Registrul de consultaţii notariale</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/registru-corespondenta">Registrul de corespondenţă</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/registru-divorturi">Registrul de divorţuri</Link>
                        </div>
                    </Item>
                </Accordion>
            </div>
        );
    }
}

export default NavigationList;
