import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, GroupItem } from "devextreme-react/form";
import DivorceRegistryGrid from './DivorceRegistryGrid/_DivorceRegistryGrid';

export class DivorceRegistry extends Component {
    static displayName = DivorceRegistry.name;

    render() {
        return(
            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Registrul de divorțuri">
                        <Item>
                            <DivorceRegistryGrid />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
                <div id="registryPopupContainer" />
                <div id="opisPopupContainer" />
            </Fragment>
        )
    }
}


