import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import LoginForm from "./LoginForm/_LoginForm"
import { ForgotPassword } from './ForgotPasswordPopup/_ForgotPassword';
import Button from 'devextreme-react/button';
import { GoogleLogin } from 'react-google-login';
import notify from 'devextreme/ui/notify';
import { LoginActions } from './../api-authorization/ApiAuthorizationConstants'

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        //loadMessages(roMessages);
        //locale(navigator.language);

        this.goToRegister = (e) => {

            window.location.href = '/register'
        }
    }



    render() {
        return (
            <div className="row my-auto h-100">
                <div className="col-sm-1 col-md-2 col-lg-4 my-auto" />
                <div className="col-sm-10 col-md-8 col-lg-4 my-auto login-container">
                    <LoginForm />
                    <ForgotPassword />

                    <div className="popup-wrapper">
                        <Button className="login-buttons" text="Register" onClick={this.goToRegister} />
                    </div>
                    <div className="popup-wrapper">
                        <GoogleLogin
                            className="login-buttons vertical-center"
                            clientId="306365759824-vj0ep0tqdqvnbm3bmpjl6le56sqe0oc0.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={(res) => {
                                var data = {
                                    FirstName: res.profileObj.givenName,
                                    LastName: res.profileObj.familyName,
                                    Email: res.profileObj.email,
                                    Token: res.tokenId
                                }
                                fetch("/account/google-login", {
                                    method: "POST",
                                    body: JSON.stringify(data),
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                }).then(response => {
                                    if (response.status === 200) {
                                        window.location.href = `/authentication/${LoginActions.Login}`
                                    }
                                    else {
                                        notify("There has been an error while authenticating your Google account. Please try again later.", "error", 3000)
                                    }
                                })
                            }}
                            onFailure={(res) => {notify("There has been an error while authenticating your Google account. Please try again later.", "error", 3000)}}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </div>
                <div className="col-sm-1 col-md-2 col-lg-4 my-auto" />
            </div>
        )
    }
}

export default Login;
