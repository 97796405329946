import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { onRowUpdating, isAuditVisible, multiLineCell, multiLineCellNumeric } from "Utils/Utils";
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Form, Popup, FormItem,
} from 'devextreme-react/data-grid';
import { divorceRegistryDataSource } from '../_DivorceRegistryDataSources';
import { RegistryReport } from 'components/RegistryReport/_RegistryReport';
import { tagBoxOptions } from 'components/Shared/TagBoxHonoraryOptions/_TagBoxHonoraryOptions';

export default class DivorceRegistryGrid extends Component {
    static displayName = DivorceRegistryGrid.name;

    constructor(props){
        super(props);

        this.grdRef = React.createRef();

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "DivorceRegistries")
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.nrCrtTextOptions = {
            placeholder: "Numărul de înregistrare va fi generat automat",
            readOnly: true
        }
    }

    render() {
        return(
            <div>
                <div className="container-datagrid">
                    <DataGrid
                        dataSource={divorceRegistryDataSource()}
                        ref={this.grdRef}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        width="auto"
                        height="84.7vh"
                        remoteOperations={true}
                        onRowUpdating={onRowUpdating}
                        onToolbarPreparing={(e) => {
                            e.toolbarOptions.items.unshift({
                                location: "after",
                                hint: "Export PDF registru",
                                widget: "dxButton",
                                options: {
                                    icon: "pdffile",
                                    onClick: (e) => {
                                        const component = ReactDOM.render(<RegistryReport Registry="divorce-registries" HasOpis={true} />, document.getElementById("registryPopupContainer"));
                                        component.forceUpdate();
                                    }
                                }
                            })
                        }}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />

                        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true} >
                            <Form colCount={1} title="Registrul de divorțuri" labelLocation="top">
                            </Form>
                            <Popup title="Registrul de divorțuri" showTitle={true} minWidth="20em" maxWidth="70em" height="80vh" />
                        </Editing>

                        <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                            <Button name="edit" cssClass="underlineBtn"/>
                            <Button name="delete" cssClass="underlineBtn" />
                            <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        </Column>

                        <Column dataField="RegistrationNumber" caption="Nr. înregistrare" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="string" editorType="dxTextBox"
                            editorOptions={this.nrCrtTextOptions}
                        >
                        </Column>

                        <Column caption="Data înregistrării" dataField="RegistrationDate" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                        >
                           <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column dataField="RequestingSpouse" caption="Partenerul reclamant" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.RequestingSpouse); }} editorType="dxTextArea"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column dataField="RecievingSpouse" caption="Partenerul pârât" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.RecievingSpouse); }} editorType="dxTextArea"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column dataField="DivorceRegistrationNumber" caption="Numărul de înregistrare din Registrul naţional de evidenţă a cererilor de divorţ" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.DivorceRegistrationNumber); }} editorType="dxTextArea"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column caption="Termen" dataField="Term" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                        >
                        </Column>

                        <Column dataField="DivorceCertificateNumber" caption="Numărul certificatului de divorţ din Registrul unic al certificatelor de divorţ" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.DivorceCertificateNumber); }} editorType="dxTextArea"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column caption="Data certificatului de divorţ din Registrul unic al certificatelor de divorţ" dataField="DivorceCertificateDate" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy"
                        >
                        </Column>

                        <Column dataField="Solution" caption="Soluţia" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.Solution); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="Honorary" caption="Onorariul încasat" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCellNumeric(e.data.Honorary); }}
                        > 
                            <FormItem visible={false} />
                        </Column>

                        <Column dataField="HonoraryList" caption="Onorariul încasat"
                            visible={false} editorType="dxTagBox" editorOptions={tagBoxOptions}
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                            <FormItem helpText="Tastați onorariul și apăsați Enter pentru a înregistra valoarea." />
                        </Column>

                        <Column dataField="PublicRegister" caption="Notarul public" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.PublicRegister); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="Observations" caption="Observaţii" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.Observations); }} editorType="dxTextArea"
                        >
                        </Column>
                    </DataGrid>
                </div>
            </div>
        )
    }
}