import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, RequiredRule, EmailRule, ButtonItem, StringLengthRule, AsyncRule, PatternRule, CompareRule } from "devextreme-react/form";
import notify from 'devextreme/ui/notify';

export class RegisterForm extends Component {
    static displayName = RegisterForm.name;

    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        }
        this.formRef = React.createRef();

        this.userNameTextOptions = {
            mode: "email"
        }

        this.passwordTextOptions = {
            mode: "password"
        }

        this.submitButtonOptions = {
            text: "Register",
            width: "13em",
            onClick: (e) => {
                this.submitButtonOnClick(e);
            }
        }

        this.submitButtonOnClick = (e) => {
            var validation = this.formRef.current.instance.validate();
            if (validation.isValid) {
                var formData = this.formRef.current.instance.option("formData");
                fetch("/api/users", {
                    method: "POST",
                    body: JSON.stringify(formData),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.status === 201) {
                        this.formRef.current.instance.resetValues();
                        notify("An email was sent to the specified address. To confirm your account, please follow the instuctions enclosed in the message.", "success", 5000)
                    }
                    else {
                        notify("Register error", "error", 3000)
                    }
                })
            }
        }

        this.emailValidation = (e) => {
            return fetch(`/api/users/checkemail?emailAddress=${e.value}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }

        this.compareRule = (e) => {
            return this.formRef.current.instance.getEditor("Password").option("value");
        }
    }

    render() {
        return (
            <Form
                className="Register-form"
                ref={this.formRef}
                showColonAfterLabel={true}
                labelLocation="left"
                colCount={1}
            >
                <Item
                    dataField="FirstName"
                    editorType="dxTextBox"
                    label={{ text: "First name" }}
                >
                    <RequiredRule message="First name address must not be empty" />
                    <StringLengthRule min={1} max={32} message="First name must have at most 32 characters" />
                </Item>
                <Item
                    dataField="LastName"
                    editorType="dxTextBox"
                    label={{ text: "Surname" }}
                >
                    <RequiredRule message="Surname address must not be empty" />
                    <StringLengthRule min={1} max={32} message="Surname must have at most 32 characters" />
                </Item>
                <Item
                    dataField="Email"
                    editorType="dxTextBox"
                    editorOptions={this.userNameTextOptions}
                    label={{ text: "Email address" }}
                >
                    <RequiredRule message="Email address must not be empty" />
                    <AsyncRule message="Email address is already associated to an account" validationCallback={this.emailValidation} />
                    <StringLengthRule min={6} max={32} message="Email address must have at most 32 characters" />
                    <EmailRule message="Email address does not have a correct format" />
                </Item>
                <Item
                    dataField="Password"
                    editorType="dxTextBox"
                    editorOptions={this.passwordTextOptions}
                    label={{ text: "Parola" }}
                >
                    <PatternRule pattern='\w*\d\w*' message="Password must contain at least one digit" />
                    <PatternRule pattern='\w*[A-Z]\w*' message="Password must contain at least one capital letter" />
                    <PatternRule pattern='\w*[!#$%&()+-/:;@[\]^_|~]\w*' message="Password must contain at least one non-alphanumeric character" />
                    <PatternRule pattern='\w*[^\s]\w*' message="Password must not contain spaces" />
                    <RequiredRule message="Password must not be empty" />
                    <StringLengthRule min={8} max={24} message="Password must have between 8 and 24 characters" />
                </Item>

                <Item
                    dataField="ConfirmPassword"
                    editorType="dxTextBox"
                    editorOptions={this.passwordTextOptions}
                    label={{ text: "Confirm password" }}
                >
                    {/* <RequiredRule message="Passwords do mot match" /> */}
                    <CompareRule message="Passwords do mot match" comparisonTarget={this.compareRule} />
                </Item>

                <ButtonItem horizontalAlignment="center" buttonOptions={this.submitButtonOptions} />
            </Form>
        )
    }
}

export default RegisterForm;
