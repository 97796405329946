import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { onRowUpdating, isAuditVisible, multiLineCell, multiLineCellNumeric } from "Utils/Utils";
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Form, Popup, FormItem
} from 'devextreme-react/data-grid';
import { protestsRegistryDataSource } from '../_ProtestsRegistryDataSources';
import { RegistryReport } from 'components/RegistryReport/_RegistryReport';
import { tagBoxOptions } from 'components/Shared/TagBoxHonoraryOptions/_TagBoxHonoraryOptions';

export default class ProtestsRegistryGrid extends Component {
    static displayName = ProtestsRegistryGrid.name;

    constructor(props) {
        super(props);

        this.grdRef = React.createRef();

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "ProtestsRegistries")
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.nrCrtTextOptions = {
            placeholder: "Numărul de înregistrare va fi generat automat",
            readOnly: true
        }
    }

    render(){
        return (
            <div>
                <div className="container-datagrid">
                    <DataGrid
                        dataSource={protestsRegistryDataSource()}
                        ref={this.grdRef}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        width="auto"
                        height="84.7vh"
                        remoteOperations={true}
                        onRowUpdating={onRowUpdating}
                        onToolbarPreparing={(e) => {
                            e.toolbarOptions.items.unshift({
                                location: "after",
                                hint: "Export PDF",
                                widget: "dxButton",
                                options: {
                                    icon: "pdffile",
                                    onClick: (e) => {
                                        const component = ReactDOM.render(<RegistryReport Registry="protests-registries" HasOpis={false} />, document.getElementById("registryPopupContainer"));
                                        component.forceUpdate();
                                    }
                                }
                            })
                        }}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true} >
                            <Form colCount={1} title="Registrul de proteste" labelLocation="top" />
                            <Popup title="Registrul de proteste" showTitle={true} minWidth="20em" maxWidth="70em" height="80vh" />
                        </Editing>

                        <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                            <Button name="edit" cssClass="underlineBtn"/>
                            <Button name="delete" cssClass="underlineBtn" />
                            <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        </Column>

                        <Column dataField="RegistrationNumber" caption="Număr de înregistrare" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="string" editorType="dxTextBox"
                            editorOptions={this.nrCrtTextOptions}
                        >
                        </Column>

                        <Column caption="Ora, ziua, luna, anul" dataField="Date" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                            editorType="dxDateBox" editorOptions={{displayFormat: "dd.MM.yyyy", dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"}} format="dd.MM.yyyy HH:mm"
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                        </Column>

                        <Column dataField="ProtestorName" caption="Numele şi prenumele celui care a cerut protestul" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.ProtestorName); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="ProtesteeName" caption="Numele şi prenumele celui contra căruia s-a cerut protestul" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.ProtesteeName); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="ComunicationTimeAndPlace" caption="Locul şi data comunicării (cu menţiunea comunicărilor efectuate)" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.ComunicationTimeAndPlace); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="OrderTranscription" caption="Transcrierea exactă a cambiei, biletului la ordin sau cecului" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.OrderTranscription); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="OrderSummons" caption="Somaţia de plată, răspunsul primit sau motivul pentru care nu sa răspuns" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.OrderSummons); }} editorType="dxTextArea"
                        >
                        </Column>

                        <Column dataField="Honorary" caption="Onorariul încasat" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCellNumeric(e.data.Honorary); }}
                        > 
                            <FormItem visible={false} />
                        </Column>

                        <Column dataField="HonoraryList" caption="Onorariul încasat"
                            visible={false} editorType="dxTagBox" editorOptions={tagBoxOptions}
                        >
                            <RequiredRule message="Câmp obligatoriu!" />
                            <FormItem helpText="Tastați onorariul și apăsați Enter pentru a înregistra valoarea." />
                        </Column>

                        <Column dataField="PublicNotary" caption="Notarul public care a întocmit protestul" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellRender={function(e) {return multiLineCell(e.data.PublicNotary); }} editorType="dxTextArea"
                        >
                        </Column>
                    </DataGrid>
                </div>
            </div>
        )
    }
}